import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, Link } from 'gatsby';
import { Location } from '@reach/router';
import { Container, Menu, Social, Hamburger } from './nav.css';

const Nav = ({ edges, location }) => {
  const main = edges[0].node.menuItems;
  const social = edges[1].node.menuItems;

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const checkLocation = () => {
    if (location.pathname != '/') {
      return 'subpage';
    }
  };

  return (
    <>
      <Container className={isOpen ? 'active' : ''}>
        <Link
          to="/"
          className="home"
          activeClassName="hidden"
          onClick={toggle}
        ></Link>
        <Menu className={checkLocation()}>
          {main.edges.map((item, i) => (
            <li key={i}>
              <a
                href={item.node.url}
                target="_blank"
                rel="noopener noreferrer"
                key={i}
              >
                {item.node.label}
              </a>
            </li>
          ))}

          <li>
            <Link to="/contact" activeClassName="active" onClick={toggle}>
              Contact
            </Link>
          </li>
        </Menu>

        <Social>
          {social.edges.map((item, i) => (
            <a
              href={item.node.url}
              className={item.node.label}
              target="_blank"
              rel="noopener noreferrer"
              key={i}
            >
              {item.node.label}
            </a>
          ))}
        </Social>
      </Container>

      <Hamburger onClick={toggle} className={isOpen ? 'active' : ''}>
        <span className="inner"></span>
      </Hamburger>
    </>
  );
};

Nav.propTypes = {
  edges: PropTypes.array,
  location: PropTypes.object,
};

const NavWithQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        cms {
          menus {
            edges {
              node {
                menuItems {
                  edges {
                    node {
                      url
                      target
                      label
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Location>
        {({ location }) => (
          <Nav {...data.cms.menus} {...props} location={location} />
        )}
      </Location>
    )}
  />
);

export default NavWithQuery;
