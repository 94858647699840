export const color = {
  accent: '#f90000',
  black: '#1A1A1A',
  gray: '#E5E5E5',
  white: '#FFF',
};

export const elevation = {
  large: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
};

export const transition = {
  pageDelay: 300,
  move: 'cubic-bezier(0.5, 0.01, 0.6, 0.99)',
  hover: 'ease',
};
