import React from 'react';
import Transition from 'components/transition';
import PropTypes from 'prop-types';
import Head from 'components/head';
import Nav from 'components/nav';
import GlobalStyle from 'global.css.js';

const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <GlobalStyle />
      <Head />
      <Nav />
      <Transition {...props}>{element}</Transition>
    </>
  );
};

wrapPageElement.propTypes = {
  element: PropTypes.any,
  props: PropTypes.any,
};

export default wrapPageElement;
