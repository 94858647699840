import { createGlobalStyle } from 'styled-components';
import { color } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export default createGlobalStyle`

  @font-face {
    font-family: 'GillSans';
    src:
      url('fonts/GillSans.eot?#iefix') format('embedded-opentype'),
      url('fonts/GillSans.woff') format('woff'), url('GillSans.ttf')  format('truetype'),
      url('fonts/GillSans.svg#GillSans') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'GillSans';
    src:
      url('fonts/GillSansBold.eot?#iefix') format('embedded-opentype'),
      url('fonts/GillSansBold.woff') format('woff'), url('GillSans.ttf')  format('truetype'),
      url('fonts/GillSansBold.svg#GillSans') format('svg');
    font-weight: bold;
    font-style: normal;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  html {
    font-size: 62.5%;
    overflow: hidden;
  }

  body {
    font-family: "GillSans", sans-serif;
    line-height: 1;
    font-size: 1.6rem;
    color: ${color.black};
    background-color: ${color.white};
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    color: ${color.accent};
    text-decoration: none;
  }

  video {
    max-width: 100%;
  }

  p {
    margin-bottom: 2rem;
  }

  ${MEDIA.PHONE`
    html {
      overflow: auto;
    }
  `};
`;
