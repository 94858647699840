import styled from 'styled-components';
import { color, transition, elevation } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.nav`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 70px;
  background: ${color.white};
  user-select: none;
  border: 1px solid ${color.gray};
  z-index: 9;
  transition: 0.2s ${transition.move};

  a.home {
    position: absolute;
    top: 30px;
    left: 15px;
    display: block;
    width: 40px;
    height: 40px;
    background-size: 100%;
    background-image: url('images/nav_home.png');
    opacity: 1;
    transition: 0.25s ${transition.move};

    &.hidden {
      opacity: 0;
      transform: translateY(-70px);
    }
  }

  ${MEDIA.PHONE`
    width: calc(100% - 60px);
    height: 100%;
    transform: translateX(-100vw);
    opacity: 0;
    box-shadow: none;

    &.active {
      transform: translateX(0px);
      opacity: 1;
      box-shadow: 0 0 100px rgba(0,0,0,1);
    }

    a.home {
      top: 30px;
      left: 20px;
    }
  `};
`;

export const Menu = styled.ul`
  top: 30px;
  list-style: none;
  width: 70px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: end;
  margin: 0 !important;
  padding: 0 !important;
  transition: 0.4s ease;

  li {
    display: flex;
    transform: rotate(-180deg);
    writing-mode: tb-lr;
    writing-mode: vertical-lr;
    width: 70px;
    align-items: center;
    margin-bottom: 30px;
    transition: 0.25s ${transition.move};

    a {
      text-decoration: none;
      color: ${color.black};
      text-transform: lowercase;
    }

    a.active::after {
      content: '';
      position: absolute;
      left: -1px;
      top: 0;
      display: block;
      width: 3px;
      height: 100%;
      background-color: ${color.black};
    }
  }

  &.subpage {
    transform: translateY(70px);
  }

  ${MEDIA.PHONE`
    top: 40px;
    left: 30px;

    li {
      transform: rotate(0deg);
      writing-mode: horizontal-tb;

      a {
        font-size: 20px;

        &.active::after {
          display: none
        }
      }
    }

    &.subpage {
      transform: translateY(60px);
    }
  `};
`;

export const Social = styled.div`
  position: absolute;
  bottom: 40px;
  left: 15px;

  a {
    display: block;
    width: 40px;
    height: 40px;
    margin-top: 10px;
    background-size: 100%;
    text-indent: -9999px;
    opacity: 0.7;
    transition: 0.2s ${transition.hover};

    &:hover {
      opacity: 1;
    }

    &.instagram {
      background-image: url('images/nav_instagram.png');
    }
    &.tumblr {
      background-image: url('images/nav_tumblr.png');
    }
  }

  ${MEDIA.PHONE`
    bottom: 30px;
    left: 30px;
    border-top: 1px solid ${color.gray};
    width: calc(100% - 60px);

    a {
      float: left;
    }
  `};
`;

export const Hamburger = styled.div`
  z-index: 99;
  background-color: ${color.black};
  width: 40px;
  height: 40px;
  position: fixed;
  right: 10px;
  top: 10px;
  display: none;

  .inner {
    display: block;
    position: absolute;
    top: 19px;
    left: 10px;
    width: 20px;
    height: 2px;
    background-color: ${color.white};
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -6px;
      width: 20px;
      height: 2px;
      background-color: ${color.white};
      transition: top 0.1s 0.25s ease-in,
      opacity 0.1s ease-in;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -6px;
      width: 20px;
      height: 2px;
      background-color: ${color.white};
      transition: bottom 0.1s 0.25s ease-in,
      transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.active {
    .inner {
      transform: rotate(225deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.1s ease-out,
        opacity 0.1s 0.12s ease-out;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.1s ease-out,
        transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }

  ${MEDIA.PHONE`
    display: block;
  `};
`;
